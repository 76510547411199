import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import clsx from 'clsx';

import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import { getShortWeekdayText } from 'lib/util/getShortWeekdayText';
import { ProductSummaryCardViewProps } from 'lib/themes/themes';
import { Weekday } from 'models/product';
import { useCustomerLedgerEnabled } from 'hooks/useCustomerLedgerEnabled';

import styles from './ProductSummaryCardView.module.css';

const ProductSummaryCardView = ({ product, productUrl }: ProductSummaryCardViewProps) => {
  const { t, i18n } = useTranslation();

  const currencyFormatter = useCurrencyFormatter();
  const customerLedgerEnabled = useCustomerLedgerEnabled();

  const allDaysOfWeek: Weekday[] = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

  return (
    <Link href={productUrl} className={styles['c-card']}>
      <div className={styles['c-card__pic']}>
        <img src={product.imageURL} alt={product.name} />
        <div className={styles['c-card__pic__labelframe']}>
          {customerLedgerEnabled && product?.shouldShowOnlyForLoggedInCustomer && (
            <div className={styles['c-card__pic__labelframe__recommend']}>
              <div>
                <img src="/static/images/ic_recommend_white.svg" alt="recommend" />
              </div>
              <p>{t('Members Only')}</p>
            </div>
          )}
        </div>
        <div className={styles['c-card__pic__tag']}>
          {product.tags.map((tag) => (
            <object key={tag}>
              {/* TODO: take appropriate action when clicking on a tag*/}
              <Link href={productUrl}>{tag}</Link>
            </object>
          ))}
        </div>
      </div>
      <div className={styles['c-card__info']}>
        <h3 className={clsx(styles['c-card__info__ttl'], 'maincolor-txt')}>{product.name}</h3>
        {product.reviewCount != null && product.reviewCount != 0 && (
          <div className="c-card__header__star">
            <img src="/static/images/ic_star_on.svg" alt="star" />
            <span>{(product.reviewRating ?? 0).toFixed(1)}</span>
            <span>({product.reviewCount})</span>
          </div>
        )}
        <p className={styles['c-card__info__desc']}>{product.description}</p>
        <p className={styles['c-card__info__week']}>
          {allDaysOfWeek.map((weekday) => (
            <>
              <span
                key={weekday}
                className={clsx(!product.operatingWeekdays.includes(weekday) && styles['close'])}
                style={
                  i18n.language === 'en-US' ? { width: '24px', textAlign: 'center' } : undefined
                }
              >
                {getShortWeekdayText(weekday, t)}
              </span>{' '}
            </>
          ))}
        </p>
        <div className={styles['c-card__info__bottom']}>
          <p className={styles['c-card__info__bottom__place']}>{product.location}</p>
          <p className={clsx(styles['c-card__info__bottom__plice'], 'maincolor-txt')}>
            {currencyFormatter.format(product.lowPrice)}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default ProductSummaryCardView;
